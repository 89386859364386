import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import CustomHeader from "../../components/header";
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import './templateDescription.scss';
import { Container, Col, Row, Card, ListGroup, ListGroupItem, Figure, Image, Button, Table, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as moment from "moment"
import { config } from "@fortawesome/fontawesome-svg-core";
import { archetypesStack } from "../../constants/templateConstants";
import healthapiled from '../../assets/icons/health-apiled.png';
import eduApiled from '../../assets/icons/education-apiled.png';
import openBankingApiled from '../../assets/icons/openbanking-apiled.png';
import serviceCloudApiled from '../../assets/icons/servicecloud-apiled.png';
import { descriptions } from '../../constants/templateDescriptions';





export default function TemplateDescription() {

  const { templateName } = useParams();

  // const location = useLocation();
  // const templateName = new URLSearchParams(location.templateName)

  console.log("templatename", templateName)
  // console.log("HHHHHH",descriptions[templateName].description)



  return (
    <div className="root" >
      <CustomHeader></CustomHeader>
      <main className="content">
        <Container >
          <div className="template-search-wrap"  >

            {templateName == 'Open Finance Accelerator' || templateName == 'Education acceleator' || templateName == 'Open Banking Accelerator' ? <div xs={12}>
              <span className="title">API Led Connectivity Architecture </span>
            </div> : <div xs={12}>
              <span className="title">Documentation</span>
            </div>}
            <div style={{ 'height': '500px', 'overflow-y': 'auto' }}>
              {templateName == 'Open Finance Accelerator' || templateName == 'Education acceleator' || templateName == 'Open Banking Accelerator' ?
                <div >
                  <span className="subtitle">Objective</span>
                </div> : <div >
                  <span className="subtitle">Description</span>
                </div>}

              <div >
                {templateName == 'Open Finance Accelerator' ?
                  <span className="paragraph" ><p ><br /><br />The Open FInance Accelerator is a comprehensive solution that provides a set of pre-built APIs specifically designed for the wealth management industry. These APIs act as a bridge, facilitating seamless integration and data exchange between different systems and applications within the wealth management ecosystem.
                    By utilizing the Wealth Management Accelerator, financial institutions can optimize their operations, improve collaboration, and deliver a better experience to their clients.<br /><br /> These APIs enable integration with external platforms, automate processes, and provide access to valuable financial data and insights.
                    One key feature of the WM Accelerator is the provision of a common API data model.<br /><br /> This data model establishes a consistent structure and format for the data used in wealth management operations. Its purpose is to ensure compatibility and interoperability across various systems and applications within the industry.
                    By adopting this common data model, financial institutions can seamlessly integrate different data sources and systems, enabling smooth data exchange and enhanced data analytics. It establishes a standardized way of representing client information, investment portfolios, transactions, and other essential wealth management data elements.
                    By leveraging the combined power of the WM API common data model and the WM APIs accelerator, firms can unlock the full potential of their systems, improve operational efficiency, and deliver exceptional services to their clients. This technology combination enables streamlined data management, enhanced collaboration,
                    and personalized wealth management solutions. Ultimately, it allows financial institutions to provide a superior experience to their clients by offering tailored and efficient wealth management services.

                  </p></span > : templateName == 'Education acceleator' ? <span className="paragraph"><p ><br /><br />The Higher Education Accelerator (HEA) is an innovative product developed by our team for Mulesoft.
                    Its primary purpose is to streamline and expedite the integration process between educational systems and Salesforce Education Cloud (SEC).
                    HEA facilitates the seamless integration of third-party systems like Moodle (Learning Management System), Stripe (Payments), and Okta (SSO), etc. ensuring a smooth and efficient flow of data between the systems.
                    Through the utilization of HEA, educational institutions can improve their overall performance and provide an optimized operations, enhance student experiences, and foster better engagement with their constituents.</p></span>
                    : templateName == 'Open Banking Accelerator' ? <span className="paragraph"><p ><br /><br />
                      <b>What is Open Banking</b><br /><br />
                      Open banking refers to a practice where banks and financial institutions open up their customer data, products, and services to third-party developers and companies through secure, standardized APIs (Application Programming Interfaces).
                      This allows customers to authorize third-party providers to access their financial information or initiate transactions on their behalf.<br /><br />

                      <b>NJC Open banking Accelerator</b><br /><br />
                      Our Open Banking Accelerator is a set of pre-built APIs, integration templates, and industry-specific components that enable financial institutions to quickly and effectively implement open banking solutions.
                      Our accelerators expedite the process of implementing open banking initiatives for financial institutions. They help reduce development time, minimize manual coding efforts, and provide a standardized approach to building APIs and integrations required for open banking compliance.
                      Our accelerators leverage MuleSoft's capabilities to streamline data sharing and integration, which are essential for successful open banking implementations.
                    </p></span>
                      : templateName == 'Multi-tier Service Cloud acceleator' ?
                        <span className="paragraph"><p ><br />
                          The goal of MuleSoft Accelerator for Salesforce Service Cloud is to provide service agents with direct access to critical data they need to service the customer, all within Salesforce Service Cloud.
                          We'll also provide them with the ability to trigger actions in other relevant applications they may not have access to, but need to be connected with.
                          <br /><br/><b>Use cases</b><br />
                          <br /><b>Salesforce Service Cloud and OMS integration</b><br />

                          <br />Use case 1 - Order history<br/>
                          Without having to toggle to a separate application, service agents will be able to see real-time order history and order status information from an external order management system (OMS) within Service Cloud.

                          <br /><br /><b>Salesforce Service Cloud and ServiceNow or Jira integration</b><br /><br />

                          Important: You must make first make important configuration changes in order to send updates from ServiceNow to Service Cloud (use cases 2 and 3). See the ServiceNow setup guide for more information.

                          <br /><br />Use case 2 - Create issues or incidents

                          <br />Use case 3 - Updates to issues or incidents<br />

                          <br />While creating a Case within Salesforce Service Cloud for a customer, service agents will be able to route relevant issues within the case to ServiceNow, if it is an ITSM issue, and Jira, if it is a bug issue. Once the ServiceNow and Jira tickets are created, the agent will also be able to see any updates made to that ticket - whether new comments are added or the status changed - all within Salesforce Service Cloud.
                        </p></span>
                        : <span className="paragraph">
                          <p ><br />{descriptions[templateName].description}</p>
                          {templateName !== 'Migration AI Agent' && templateName !== 'Integration AI Agent' && templateName !== 'Design AI Agent'?
                          <>
                          <div className="bullet-div">
                            <ul className="custom-bullet">
                              {descriptions[templateName].functions.map((item, index) => (


                                <li key={index}>{item}</li>

                              ))}
                            </ul>
                          </div>
                          <br />
                          <div >
                            <span className="subtitle">Additional details</span><br /><br />
                          </div>
                          <div className="bullet-div">
                            <ul className="custom-bullet">
                              {descriptions[templateName].additionalDetails.map((item, index) => (


                                <li key={index}>{item}</li>

                              ))}
                            </ul>
                          </div></>:null}
                        </span>}
              </div>
              <div>
                {templateName == 'Open Finance Accelerator' ?
                  <span><img src={healthapiled} width="1000" height="800" /></span> : templateName == 'Education acceleator' ?
                    <>
                      <br /><br /><br /><br />
                      <span><img src={eduApiled} width="1000" height="800" /></span></> :
                    templateName == 'Open Banking Accelerator' ?
                      <>
                        <br /><br /><br /><br />
                        <span><img src={openBankingApiled} width="1000" height="800" /></span></>
                      :
                      templateName == 'Multi-tier Service Cloud acceleator' ?
                      <>
                        <br /><br /><br /><br />
                        <span><img src={serviceCloudApiled} width="1100" height="700" /></span></>: null
                }
              </div>
            </div>
          </div>

        </Container >
      </main>
    </div>
  );


}
