import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../../components/header";
import './accessmanagement.scss';
import { Accordion, Card, Container, Col, Row, Modal, Button } from 'react-bootstrap';
import socketIOClient from "socket.io-client";
import notFoundIcon from '../../assets/icons/nodata.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Icon from 'react-crud-icons';
import "../../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import { sidemenuList, orgList, azureList,azureAccountList,githubList,gitlabList, jenkinsList, muleEnvList, grafanaList, bitbucketList, awsList,awsAccountList,gkeList, flexGatewayList, VMEC2List, registerFlexGatewayList,sonarList,rtfRequestList,elkList } from "../../constants/configurationPageConstants";
import {
    muleTableHeader, gitTableHeader, rtfTableHeader, bitbucketTableHeader, awsTableHeader,
    azureTableHeader, jenkinsTableHeader, grafanaTableHeader
} from "../../constants/tableHeaderConstants.js";
import { anypoint, domain, ENDPOINT } from "../../config.js";
import ConfirmModal from "../common/confirmmodal";
import AddNewResource from "../common/addnewresourcemodal";
import AddActiveMuleContent from "../common/addactivemulecontent";
import useConfirm from "../../hooks/useconfirm";
import ConfirmDialog from "../../components/confirm-modal/index";
import {  TableName, Table, TableBodyforGke } from "../../components/tableComponent/index"
import MultiCard from '../../components/multiCard';
import JenkinsCard from '../../components/jenkinsCard/index'
import Chart from "../../components/chart";
import { ContactsOutlined } from "@material-ui/icons";
import { NoSettingsFound } from "../../utilities";


function Accessmanagement(props) {
    const navigate = useNavigate()
    const [fileBase64String, setFileBase64String]=useState("");
    const [file, setFile] = useState([]);
    const { confirm } = useConfirm();
    const [state, setState] = useState({
        username: "",
        password: "",
        azusername:"",
        azpassword:"",
        organization: "",
        location: "",
        resource_group_name: "",
        containerRepository: "",
        azure_container_registry_name: "",
        aks_cluster_name: "",
        open: false,
        resourcesAvailable: false,
        resource: [],
        azaccount: [],
        grafana: [],
        github: [],
        gitlab:[],
        jenkins: [],
        mule: [],
        muleOrg: [],
        sonarqube:[],
        elk:[],
        ec2List:[],
        flexGateways:[],
        registeredGateways:[],
        gkeresource:[],
        gkecluster:[],
        myclass: '',
        activePage: 'Anypoint',
        gitUsername: '',
        gitPat: '',
        newOrg: '',
        newGkeAcc:'',
        gitRepo: '',        
        muleOrganization: '',
        activeMuleContent: 'VPC',
        selectedMuleOrg: '',
        selectedMuleUsername: '',
        selectedMulePassword: '',
        selectedMuleOrgId: '',
        muleSettingsId: '',
        sonarServer:'',
        sonarUsername:'',
        sonarPassword:'',
        sonarConnectionName:'',
        rtfName:'',
        elkIP:'',
        elkUsername:'',
        elkPassword:'',
        organizationEnvironment: ["dev", "prod", "test"],
        deployTargetEnv: '',
        targetCluster: '',
        rtfData: [],
        bitbucket: [],
        awsresources: [],
        awsaccount:[],
        azureValidation: {},
        jenkinsValidation: {},
        sonarValidation:{},
        muleValidation: {},
        grafanaValidation: {},
        awsValidation: {},
        bitbucketValidation: {},
        githubValidation: {},
        gitlabValidation:{},
        elkValidation:{},
        rtfValidation:{},
        muleOrgValidation: {},
        vmEc2Validation:{},
        registerFlexGatewayValidation:{},
        flexGatewayValidation:{},
        gkeValidation:{},
        azureResourceCreating: false,
        gkeResourceCreating:false,
        awsResourceCreating: false,
        rtfResourceCreating:false,
        muleOrgNotDelete: false,
        showMuleSettings: false,
        showConfirmModal: false,
        confirm: false,
        id: '',
        index: '',
        stateVar: '',
        url: '',
        apiPathLength:0

    });
    function onChange(event) {
        const { name, value ,checked ,type } = event.target;
        console.log(checked);
        type === "checkbox" ? setState(prevState => ({ ...prevState, [name]: checked })) : setState(prevState => ({ ...prevState, [name]: value }));
        console.log(event.target)
       
    }
    function createAzureCluster (resource) {
        console.log(resource);
        setState(prevState =>({...prevState,username : resource.azpusername }))
        setState(prevState =>({...prevState,password : resource.azppassword}))
        setState(prevState =>({...prevState,azusername : resource.azusername}))
        setState(prevState =>({...prevState,azpassword : resource.azpassword}))
        setState(prevState =>({...prevState,aztenantid : resource.aztenantid}))
        setState(prevState =>({...prevState,azDevopsPat : resource.azDevopsPat}))
        setState(prevState =>({...prevState,organization : resource.organization}))
        setState(prevState =>({...prevState,activeTab : "azCluster"}))
        handleOpen();


    }
    function createAwsCluster (resource) {
        console.log(resource);
        setState(prevState =>({...prevState,awsaccesskey : resource.awsaccesskey }))
        setState(prevState =>({...prevState,awsregion : resource.awsregion}))
        setState(prevState =>({...prevState,awsaccesskeyid : resource.awsaccesskeyid}))
        setState(prevState =>({...prevState,activeTab : "awsCluster"}))
        handleOpen();


    }

    function createEC2Instance (resource) {
        console.log(resource);
        setState(prevState =>({...prevState,awsaccesskey : resource.awsaccesskey }))
        setState(prevState =>({...prevState,awsregion : resource.awsregion}))
        setState(prevState =>({...prevState,awsaccesskeyid : resource.awsaccesskeyid}))
        setState(prevState =>({...prevState,activeTab : "ec2"}))
        handleOpen();
    }
    function gotoPage(url){
        navigate(url);
    }
    // function handleChange(event) {
        // setFile(event.target.files)
        const handleChange = e => {
            const fileReader = new FileReader();
            fileReader.readAsText(e.target.files[0], "UTF-8");
            fileReader.onload = e => {
              setFile(e.target.result);
            };
          };
      
    function getBodyContent() {

        switch (state.activePage) {
            case 'Anypoint':
                return (
                    <div>
                        <Row className="no-margin">
                            <Col xs={10} className="no-padding no-margin"></Col>
                            <Col xs={2} className="no-padding no-margin"><Button className="create-az-btn" onClick={() => handleOpen()}> Add New</Button></Col>
                        </Row>
                        <Row xs={12} className="settings-details-row setting-table-wrap">
                            {state.muleOrg && state.muleOrg.length ? state.muleOrg.map((muleOrgItem, index) => {

                                return (
                                    <Col className="org-panel-container" xs={12}>
                                        <Accordion key={muleOrgItem._id} defaultActiveKey={muleOrgItem._id}>
                                            <Card>
                                                <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey={muleOrgItem._id}>
                                                    {muleOrgItem.organization}
                                                </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={muleOrgItem._id}>
                                                <Card.Body>
                                                    {
                                                        state.mule && state.mule.length  ? state.mule.map ((muleItem,index) =>{
                                                           return (
                                                            muleItem.muleOrg === muleOrgItem.organization ? 
                                                            <div className="card-body-title" onClick={()=>gotoPage(`/anypoint/${muleOrgItem._id}/${muleItem._id}`)}>
                                                                {muleItem.muleUsername}
                                                            </div>: null
                                                           )
                                                        
                                                        }) : null
                                                    }
                                                        <div className="mule-btn-wrap">
                                                                  {/* <TableBodyWithCondition type="mule" data={state.mule} muleOrgItem={muleOrgItem} deleteResource={deleteResource} url='mule' stateVar='mule' /> */}
                                                                   <button className=" create-az-btn btn btn-primary settings-add" onClick={() => createMuleSettings(muleOrgItem.organization)}>
                                                                        + Add Mule Settings
                                                                    </button>
                                                            </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            </Accordion>
                                    </Col>
                                )
                            })
                                : <NoSettingsFound message='No organization  found' />}
                        </Row>
                    </div>
                );
            case 'Azure':
                console.log("azaccount",state.azaccount)

                return (
                    <div>
                        <Row className="no-margin">
                               <TableName name='' handleOpen={handleOpen} />
                      
                                     {
                                        state.azaccount.length ?
                                            state.azaccount.map((resource, index) => {
                                                let chartContent = {"Username":resource.azpusername, "DevOps Org":resource.organization,  }
                                                return (
                                                    <Row xs={12} className="settings-details-row setting-table-wrap">
                                                         <Col xs={12} >
                                                         <Row>
                                                             <Col xs={12}>
                                                                <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                                                <button className=" create-az-btn btn btn-primary settings-add" onClick={() => createAzureCluster(resource)}>
                                                                                + Add Azure Cluster
                                                                </button>
                                                             </Col>
                                                             
                                                         </Row>
                                                        <Row>
                                                            <Col xs={12}>
                                                                 <label>AKS Cluster</label>
                                                            </Col>
                                                            <Col xs={12}>
                                                                 {
                                                                state.resource.length ? 
                                                                    state.resource.map( (azresource,index) =>{
                                                                         let resourceContent = {"AKS Cluster":azresource.aks_cluster_name, "Resource Group":azresource.resource_group_name,  }
                                                                         return (
                                                                           azresource.azusername === resource.azusername ?   <Chart chartData={resourceContent} gridval="6" theme="light"/> : null
                                                                         )
                                                                    }) : <NoSettingsFound message='No AKS Clusters available' />
                                                            }
                                                            </Col>
                                                           
                                                        </Row>
                                                    </Col>
                                                    </Row>
                                                    
                                                )})
                                                : <NoSettingsFound message='No azure settings found' />}
                                   
                                                
                              

                        </Row>

                    </div>
                );
          
            case 'Github':
                return (
                     <div>
                        {/* <Table name="Github Settings" handleOpen={handleOpen} headerList={gitTableHeader} message='No git settings found'
                            resourceList={state.github} deleteResource={deleteResource} url='repo' stateVar='github' /> */}

                        <Row className="no-margin ">
                            <TableName name='' handleOpen={handleOpen} />
                        
                         <Row xs={12} className="settings-details-row setting-table-wrap">


                            {state.github.length ?
                                state.github.map((item, ind) => {
                                    const headerData ={ username:item.gitUsername}
                                    const footerData ={ PAT: item.gitPat }
                                    return (
                                        <Col>
                                            <MultiCard headerData={headerData} footerData={footerData} theme="dark"/>
                                        </Col>
                                    )
                                })
                                : <NoSettingsFound message='No git settings found' />}
                        </Row>
                        </Row>
                    </div>

                );
                case 'GitLab':
                return (
                     <div>
                        {/* <Table name="Github Settings" handleOpen={handleOpen} headerList={gitTableHeader} message='No git settings found'
                            resourceList={state.github} deleteResource={deleteResource} url='repo' stateVar='github' /> */}

                        <Row className="no-margin ">
                            <TableName name='' handleOpen={handleOpen} />
                        
                         <Row xs={12} className="settings-details-row setting-table-wrap">


                            {state.gitlab.length ?
                                state.gitlab.map((item, ind) => {
                                    const headerData ={ username:item.gitlabUsername}
                                    const footerData ={ PAT: item.gitlabPat }
                                    return (
                                        <Col>
                                            <MultiCard headerData={headerData} footerData={footerData} theme="dark"/>
                                        </Col>
                                    )
                                })
                                : <NoSettingsFound message='No git lab settings found' />}
                        </Row>
                        </Row>
                    </div>

                );
            case 'Jenkins':
                return (
                     <div>
                        {/* <Table name="Jenkins Settings" handleOpen={handleOpen} headerList={jenkinsTableHeader} message='No Jenkins settings found'
                        resourceList={state.jenkins} deleteResource={deleteResource} url='jenkins' stateVar='jenkins' /> */}
                        <Row className="no-margin">
                            <TableName name='' handleOpen={handleOpen} />
                       
                        <Row xs={12} className="settings-details-row setting-table-wrap">
                        {state.jenkins.length ?
                            state.jenkins.map((item, ind) => {
                                let chartContent = {"Server":item.jenkinsServer, "Username":item.jenkinsUsername,"Token":item.jenkinsToken  }
                                        return (
                                                <Col xs={6}>
                                                <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                            </Col>
                                        )})
                            : <NoSettingsFound message='No jenkins settings found' />}
                        {/* </Row> */}
                        </Row>
                        </Row>
                    </div>

                );
            case 'Grafana':
                return (
                    <Table name="" handleOpen={handleOpen} headerList={grafanaTableHeader} message='No grafana settings found'
                        resourceList={state.grafana} deleteResource={deleteResource} url='grafana' stateVar='grafana' />
                );
            case 'AWS':
                return (
                    <div>
                        <Row className="no-margin">
                                <TableName name='' handleOpen={handleOpen} />
                                <Row className="settings-details-row setting-table-wrap">
                                    {
                                        state.awsaccount.length ?  state.awsaccount.map((resource, index) => {
                                            
                                                             let chartContent = {"AWS Access Key":resource.awsaccesskey, "AWS Access Key ID":resource.awsaccesskeyid,"Location":resource.location, "AWS Cluster": resource.awscluster  }
                                                             return (
                                                                 <>
                                                                    <Accordion key={resource._id} defaultActiveKey={resource._id}>
                                                                        <Card>
                                                                            <Card.Header>
                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={resource._id}>
                                                                                {resource.awsaccesskey}
                                                                            </Accordion.Toggle>
                                                                            </Card.Header>
                                                                            <Accordion.Collapse eventKey={resource._id}>
                                                                                <Card.Body>
                                                                                    <div onClick={()=>gotoPage(`/aws/${resource.awsaccesskeyid}`)}> Go to AWS Settings</div>
                                                                                </Card.Body>
                                                                            </Accordion.Collapse>
                                                                        </Card>
                                                                        </Accordion>
                                                                 </>
                                                                
                                                           
                                                            )
                                            
                                                        })
                                            : <NoSettingsFound message='No AWS Settings Found' />}
                                </Row>


                        </Row>

                    </div>
                );
            case 'Bitbucket':
                return (
                          <div>
                            <Row className="no-margin">
                                    <TableName name='' handleOpen={handleOpen} />
                                    <Row className="settings-details-row setting-table-wrap">
                                        {
                                            state.bitbucket.length ?  state.bitbucket.map((bitItem, index) => {
                                                    let chartContent = {"Username":bitItem.bitbucketUsername, "Workspace":bitItem.bitbucketWorkspace,"Bit Project":bitItem.bitbucketProject, "PAT": bitItem.bitbucketPat  }
                                                    return (
                                                        <Col xs={6}>
                                                            <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                                        </Col>
                                                    )
                                                })
                                                : <NoSettingsFound message='No bitbucket settings found' />}
                                    </Row>

                            </Row>

                    </div>
                );
            case 'VM': 
                return (
                          <div>
                            <Row className="no-margin ">
                                    <TableName name='' handleOpen={handleOpen} />
                                    <Row className="settings-details-row setting-table-wrap">
                                        {
                                            state.ec2List && state.ec2List.length ?  state.ec2List.map((ec2Item, index) => {
                                                    let chartContent = {"AWS_ACCESS_KEY_ID":ec2Item.AWS_ACCESS_KEY_ID, "INSTANCE_ID":ec2Item.INSTANCE_ID,"PUBLIC_IP":ec2Item.PUBLIC_IP, "VPC_ID": ec2Item.VPC_ID ,"KEY_NAME" : ec2Item.KEY_NAME }
                                                    return (
                                                        <Col xs={6}>
                                                            <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                                        </Col>
                                                    )
                                                })
                                                : <NoSettingsFound message='Vm instances not found' />}
                                    </Row>

                            </Row>

                    </div>
                ); 
             case 'Flex Gateway': 
                return (
                          <div>
                            <Row className="no-margin ">
                                    <TableName name='' handleOpen={handleOpen} />
                                    <Row className="settings-details-row setting-table-wrap">
                                        {
                                            state.flexGateways && state.flexGateways.length ?  state.flexGateways.map((flexItem, index) => {
                                                    let chartContent = {"Name":flexItem.name, "Machine ID":flexItem.machineId,"UUID":flexItem.uuid  }
                                                    return (
                                                        <Col xs={6}>
                                                            <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                                        </Col>
                                                    )
                                                })
                                                : <NoSettingsFound message='No Flex gateway settings found' />}
                                    </Row>

                            </Row>

                    </div>
                );
             case 'Register Flex Gateway': 
                return (
                          <div>
                            <Row className="no-margin ">
                                    <TableName name='' handleOpen={handleOpen} />
                                    <Row className="settings-details-row setting-table-wrap">
                                        {
                                            state.registeredGateways && state.registeredGateways.length ?  state.registeredGateways.map((flexItem, index) => {
                                                    let chartContent = {"APP_NAME":flexItem.appName, "DOMAIN_URL":flexItem.domainUrl,"FLEX_ID":flexItem.gatewayId,  }
                                                    return (
                                                        <Col xs={6}>
                                                            <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                                        </Col>
                                                    )
                                                })
                                                : <NoSettingsFound message='Registered application list is empty' />}
                                    </Row>

                            </Row>

                    </div>
                );
                case 'GKE': 
                return (
                    <div>
                        <Row className="no-margin">
                            <Col xs={10} className="no-padding no-margin"></Col>
                            <Col xs={2} className="no-padding no-margin"><Button className="create-az-btn" onClick={() => handleOpen()}> Add New</Button></Col>
                        </Row>
                        <Row xs={12} className="settings-details-row setting-table-wrap">
                            {state.gkeresource && state.gkeresource.length ? state.gkeresource.map((gkeAccount, index) => {

                                return (
                                    <Col className="org-panel-container" xs={12}>
                                        <Row className="org-panel">
                                            
                                            <span className="orgname" onClick={() => changeServiceAccount(gkeAccount.serviceAccEmail)}>{gkeAccount.serviceAccEmail}</span>
                                            
                                        </Row>
                                        <div className={"mule-org-panel not-toggled" }>
                                            <Row className="" >
                                                {
                                                    
                                                        <>
                                                            <Col xs={6}>
                                                                  <TableBodyforGke type="gke" data={state.gkecluster} gkeAccount={gkeAccount} deleteResource={deleteResource} url='gkecluster' stateVar='gkecluster' />
                                                                   <button className=" create-az-btn btn btn-primary settings-add" onClick={() => createGkeCluster(gkeAccount.serviceAccEmail)}>
                                                                        + Add Cluster
                                                                    </button>
                                                            </Col>
                                                            {/* <Col xs={6}>
                                                                  <TableBodyWithCondition type="rtf" data={state.rtfData} muleOrgItem={muleOrgItem} deleteResource={deleteResource} url='mule' stateVar='mule' />
                                                                    <button className="create-az-btn btn btn-primary settings-add" onClick={() => setActiveMuleContent("RTF", muleOrgItem)} > + Add RTF </button>
                                                            </Col> */}
                                                        </>
                                                    }
                                                        
                                            </Row>
                                            
                                        </div>

                                    </Col>
                                )
                            })
                                : <NoSettingsFound message='No GKE Settings found' />}
                        </Row>
                    </div>
                    //       <div>
                    //          <Row className="no-margin">
                    //             <TableName name='' handleOpen={handleOpen} />
                    //             <Row className="settings-details-row setting-table-wrap">
                    //                 {
                    //                     state.gkeresource.length ?  state.gkeresource.map((resource, index) => {
                    //                                          let chartContent = {"Service Account Email":resource.serviceAccEmail
                    //                                          , "GKE Cluster":resource.gkeClusterName,"Location":resource.gkeRegion
                    //                                            }
                    //                                          return (
                    //                                             <Col xs={6}>
                    //                                                 <Chart chartData={chartContent} gridval="6" theme="dark"/>
                    //                                             </Col>
                    //                                         )
                    //                                     })
                    //                         : <NoSettingsFound message='No GKE Settings Found' />}
                    //             </Row>

                    //     </Row>

                           

                    // </div>
                ); 
                case 'Sonarqube': 
                console.log("length",state.sonarqube.length,state.sonarqube)
                return (
                          <div>
                             <Row className="no-margin">
                                <TableName name='' handleOpen={handleOpen} />
                                <Row className="settings-details-row setting-table-wrap">
                                    {
                                        
                                        state.sonarqube.length ?  state.sonarqube.map((resource, index) => {

                                                             let chartContent = {"SonarQube Server":resource.sonarServer, "Username":resource.sonarUsername,"Password":resource.sonarPassword}
                                                             return (
                                                                <Col xs={6}>
                                                                    <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                                                </Col>
                                                            )
                                                        })
                                            : <NoSettingsFound message='No SonarQube Settings Found' />}
                                </Row>
                        </Row>
                    </div>
                ); 
                case 'ELK': 
                console.log("length",state.elk.length,state.elk)
                return (
                          <div>
                             <Row className="no-margin">
                                <TableName name='' handleOpen={handleOpen} />
                                <Row className="settings-details-row setting-table-wrap">
                                    {
                                        
                                        state.elk.length ?  state.elk.map((resource, index) => {

                                                             let chartContent = {"ELK IP":resource.elkIP, "Username":resource.elkUsername,"Password":resource.elkPassword}
                                                             return (
                                                                <Col xs={6}>
                                                                    <Chart chartData={chartContent} gridval="6" theme="dark"/>
                                                                </Col>
                                                            )
                                                        })
                                            : <NoSettingsFound message='No ELK Settings Found' />}
                                </Row>
                        </Row>
                    </div>
                );                                    
            default: break;
        }
    }

    function getResource(item, stateVar) {
        console.log("url",domain + '/api/v1/' + item)
        const requestOptions = {
            method: 'GET',
            headers: { 'x-access-token': localStorage.getItem('token') }
        };
        try {
            fetch(domain + '/api/v1/' + item, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("getresource result",item,data);
                    if (item.indexOf('mule') !== -1)
                        setState(prevState => ({ ...prevState, [stateVar]: data.mule }));
                    else if (item.indexOf('jenkins') !== -1)
                        setState(prevState => ({ ...prevState, [stateVar]: data.jenkins }));
                    else if (item.indexOf('sonarqube') !== -1)
                        setState(prevState => ({ ...prevState, [stateVar]: data.sonarqube }));    
                    else
                        setState(prevState => ({ ...prevState, [stateVar]: data }));
                });
        }
        catch (e) {
            console.log("Fetching Error", e)
        }
    }

    const deleteResource = async (id, item, stateVar) => {
        const isConfirmed = await confirm('Do you confirm your choice?');
        setState(prevState => ({ ...prevState, confim: false }))
        if (isConfirmed) {
            var obj = { "id": id }
            const requestOptions = {
                method: 'DELETE',
                headers: { 'x-access-token': localStorage.getItem('token'), 'Content-Type': 'application/json' },
                body: JSON.stringify(obj)
            };
            let url = (item == 'resource') ? (domain + `/api/v1/` + item) : domain + `/api/v1/` + item + `?id=${id}`
            try {
                fetch(url, requestOptions)
                    .then(response => { var data = response.json(); console.log("delete", data) })
                    .then(data => {
                        getResource(item, stateVar)
                    });
            }
            catch (e) { console.log("Error:", e) }
        }

    }
    function postResource(postObj, url, stateVar) {
        console.log("inside Post",postObj )
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
            body: JSON.stringify(postObj)
        };
        console.log("requset",requestOptions);
        fetch(domain + '/api/v1/' + url, requestOptions)
            .then(response => response.json())
            .then(data => {
             getResource(url, stateVar);
            });

    }

    function Validation(resourceLists, validationState) {
        
        resourceLists.map((item) => {
            if (state[item.stateVal] === '' || state[item.stateVal] === undefined) {
                let validObj = state[validationState];
                validObj[item.stateVal] = false;
                setState(prevState => ({ ...prevState, [validationState]: validObj }))
            }
            else {
                let validObj = state[validationState];
                validObj[item.stateVal] = true;
                setState(prevState => ({ ...prevState, [validationState]: validObj }))

            }
        })
        console.log("valll", state.rtfValidation)
        if (Object.values(state[validationState]).every(item => item))
            return true
    }

    useEffect(() => {
        getResource('repo', 'github');
        getResource('elk','elk')
        getResource('gitlab', 'gitlab');
        getResource('azresource', 'resource');
        getResource('awsresource', 'awsresources');
        getResource('gkeresource', 'gkeresource');
        getResource('jenkins', 'jenkins');
        getResource('mule', 'mule');
        getResource('mule/org', 'muleOrg');
        getResource('mule/rtf', 'rtfData');
        getResource('bitbucket', 'bitbucket');
        getResource('vm/ec2', 'ec2List');
        getResource('flex', 'flexGateways');
        getResource('register/flex', 'registeredGateways');
        getResource('sonarqube', 'sonarqube');
        getResource('gkecluster', 'gkecluster');
        getResource('awsaccount', 'awsaccount');
        getResource('azaccount', 'azaccount');

        
        const socket = socketIOClient(ENDPOINT);
        socket.on("create-resource", data => {
            if (data.status === "done") {
                getResource();
            }

        })
    }, [])

    const handleOpen = () => {
        console.log('Show modal')
        setState(prevState => ({ ...prevState, showModal: true }))
    }
    const handleClose = () => {
        setState(prevState => ({ ...prevState, showModal: false }))
        setState(prevState => ({ ...prevState, activeTab: "" }))
    }

    const openMuleSettings = () => {

        setState(prevState => ({ ...prevState, showMuleSettings: true }))
    }
    const closeMuleSettings = () => {
        handleClose();
        setState(prevState => ({ ...prevState, showMuleSettings: false }))
    }


    const submit = async () => {
        let valid = await Validation(azureList, 'azureValidation')
        if (valid) {
            handleClose();
            setState(prevState => ({ ...prevState, azureResourceCreating: true }))

            setTimeout(function () {
                setState(prevState => ({ ...prevState, azureResourceCreating: false }))

            }, 600000)
            let orgName = state.organization.split("/dev.azure.com/")[1]
            var postObj =
            {
                "azpusername": state.username,
                "azppassword": null,
                "azusername": state.azusername,
                "azpassword": state.azpassword,
                "aztenantid": state.aztenantid,
                "azDevopsPat": state.azDevopsPat,
                "organization": state.organization,
                "location": "eastus",
                "createCluster": JSON.parse(state.createCluster),
                "resource_group_name": (state.aksresourcegroup + (Math.random() + 1).toString(36).substring(7) + 'rg').replace(/[^a-zA-Z ]/g, ""),
                "containerRepository": (state.aksresourcegroup + (Math.random() + 1).toString(36).substring(7) + 'containerRepo').replace(/[^a-zA-Z ]/g, ""),
                "azure_container_registry_name": (state.aksresourcegroup + (Math.random() + 1).toString(36).substring(7) + 'containerreg').replace(/[^a-zA-Z ]/g, ""),
                "aks_cluster_name": (state.aksresourcegroup + (Math.random() + 1).toString(36).substring(7) + 'cluster').replace(/[^a-zA-Z ]/g, "")
            }
            console.log(postObj);
            postResource(postObj, 'azresource', 'resource');

        }
    }
    const submitResource = async (resource) => {
        console.log("inside submit" + resource);
        let valid = (resource == 'github') ? submitRepo() : (resource == 'jenkins') ? submitJenkins() : (resource == 'bitbucket') ?
            createBitBucket() : (resource == 'grafana') ? createGrafana() : (resource === 'aws') ? createAWS() :
                (resource == 'azure') ? submit() : (resource == 'mule') ? submitMule() : (resource == 'muleorg') ? submitMuleOrg() :  (resource == 'vmec2') ?
                 createVmEc2() :(resource == 'flexgateway') ? createFlexGateway(): (resource == 'registerflex') ? 
                 registerFlexGateway() :(resource == 'addgkeaccount')? addGkeAccount():(resource=== 'sonarqube')?submitSonarqube():(resource=== 'gkeCluster')?
                 addGkeCluster():(resource=='azaccount')?submitAzureAccount():(resource === 'awsAccount')?submitAwsAccount():(resource === 'gitlab')?submitGitlab():(resource === 'elk')?submitElk():''

    }
    const submitRepo = async () => {
        console.log("inside Repo Submit")
        let valid = await Validation(githubList, 'githubValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "gitUsername": state.gitUsername,
                "gitPat": state.gitPat,
                "gitRepo": state.gitRepo
            }

            postResource(postObj, 'repo', 'github')
        }
    }
    const submitGitlab = async () => {
        console.log("inside GitLab Submit")
        let valid = await Validation(gitlabList, 'gitlabValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "gitlabUsername": state.gitlabUsername,
                "gitlabPat": state.gitlabPat,
            }

            postResource(postObj, 'gitlab', 'gitlab')
        }
    }
    const submitAzureAccount = async () =>{
        let valid = await Validation(azureAccountList, 'azureValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "azpusername": state.username,
                "azppassword": null,
                "azusername": state.azusername,
                "azpassword": state.azpassword,
                "aztenantid": state.aztenantid,
                "azDevopsPat": state.azDevopsPat,
                "organization": state.organization
            }
            postResource(postObj, 'azaccount', 'azaccount');

        }
    }
    const submitAwsAccount = async () =>{

        let valid = await Validation(awsAccountList, 'awsValidation')
        console.log("AWS VALIDATION",valid)
        if (valid) {
            handleClose();
            var postObj = {
                "awsaccesskey": state.awsaccesskey,
                "awsaccesskeyid": state.awsaccesskeyid
            }
            console.log(postObj);
            setState(prevState => ({ ...prevState, azureResourceCreating: true }))
            postResource(postObj, 'awsaccount', 'awsaccount')
        }
    }
    const createBitBucket = async () => {
        let valid = await Validation(bitbucketList, 'bitbucketValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "bitbucketUsername": state.bitbucketUsername,
                "bitbucketPat": state.bitbucketPat,
                "bitbucketWorkspace": state.bitbucketWorkspace,
                "bitbucketProject": state.bitbucketProject
            }
            postResource(postObj, 'bitbucket', 'bitbucket')
        }

    }
    const createVmEc2= async () => {
        let valid = await Validation(VMEC2List, 'vmEc2Validation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "accessKeyId": state.awsaccesskeyid,
                "keyName": state.vmKeyName
            }
            postResource(postObj, 'vm/ec2', 'vmEc2')
        }

    }
     const createFlexGateway= async () => {
        let valid = await Validation(flexGatewayList, 'flexGatewayValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "ec2Id": state.instanceId,
                "envId": state.flexEnvId,
                "orgId": state.mule.filter(muleItem => muleItem.muleOrg === state.flexOrgId )[0].muleOrgId,
                "type":state.flexType,
                "name": state.flexName
            }
            console.log(postObj);
            postResource(postObj, 'flex', 'flexGateways')
        }

    }
    const registerFlexGateway= async () => {
        let valid = await Validation(registerFlexGatewayList, 'registerFlexGatewayValidation')
        if (valid) {
            handleClose();
            let routes =[
                {
                    "rules":[]
                }
            ];
            
            for(var i=0 ;i <= state.apiPathLength ; i++){
                
                routes[0].rules.push({"path": i > 0 ?state['apiPath'+i] :state['apiPath'] })
            }
            console.log(routes);
            var postObj =
            {
                "flexGateWayId":state.flexGateways.filter(flex => flex.name === state.flexGateWayId)[0]._id,
                "appName": state.flexAppName,
                "appUrl": state.flexAppUrl,
                "apiPath": state.flexAppPath,
                "appInstance":state.flexAppInstance,
                "name": state.flexAppName,
                "routes":routes,
                "policies": [
                    state.basicAuth ?
                    {
                        "policyRef": {
                            "name":"http-basic-authentication-flex"
                        },
                        "config":{
                            "username": state.basicAuthUsername,
                            "password": state.basicAuthPassword
                        }
                    
                    } : {},
                    state.rateLimiting ?
                    {
                        "policyRef": {
                            "name": "rate-limiting-flex"
                        },
                        "config":{
                            "exposeHeaders": true,
                            "rateLimits":[{
                                "maximumRequests" :state.flexMaxRequest,
                                "timePeriodInMilliseconds" : state.flexTimePeriod
                            }],
                            "keySelector": "#[attributes.queryParams['identifier']]"
                        }
                    } : {}
                ]
            }
            console.log(postObj)
            postResource(postObj, 'flex/register', 'registeredFlexGateways')
        }

    }
    const submitJenkins = async () => {
        let valid = await Validation(jenkinsList, 'jenkinsValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "jenkinsServer": state.jenkinsServer,
                "jenkinsToken": state.jenkinsToken,
                "jenkinsUsername": state.jenkinsUsername
            }
            postResource(postObj, 'jenkins', 'jenkins')
        }

    }
    const submitElk = async () => {
        let valid = await Validation(elkList, 'elkValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "elkIP": state.elkIP,
                "elkUsername": state.elkUsername,
                "elkPassword": state.elkPassword
            }
            console.log("ELK",postObj)
            postResource(postObj, 'elk', 'elk')
        }

    }
    const createGrafana = async () => {
        let valid = await Validation(grafanaList, 'grafanaValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "grafanaServer": state.grafanaServer,
                "grafanaToken": state.grafanaToken
            }
            postResource(postObj, 'grafana', 'grafana')
        }
    }
    const submitMule = async () => {
        let valid = await Validation(muleEnvList, 'muleValidation')
        // if(valid){  
        if (state.muleValidation.muleClientId && state.muleValidation.muleClientPassword && state.muleValidation.anypointUsername
            && state.muleValidation.anypointPassword) {
            handleClose();
            var postObj =
            {
                "muleClientId": state.muleClientId,
                "muleClientSecret": state.muleClientPassword,
                "muleUsername": state.anypointUsername,
                "mulePassword": state.anypointPassword,
                "qaUsername": state.qaUsername,
                "qaPassword": state.qaPassword,
                "prodUsername": state.prodUsername,
                "prodPassword": state.prodPassword,
                "muleOrg": state.newOrg
            }
            console.log("postobj",postObj)
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
                body: JSON.stringify(postObj)
            };
            try {
                fetch(domain + '/api/v1/mule', requestOptions)
                    .then((response) => {
                        if (!response.ok) {
                            console.log(response)
                            setState(prevState => ({ ...prevState, validationError: "Error creating mule settings, please verify your credentials and organization details" }))
                            setState(prevState => ({ ...prevState, validationFailed: true }));
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log(data);
                        getResource('mule', 'mule');
                    });
            } catch (e) {
                console.log(e)
                setState(prevState => ({ ...prevState, validationError: e }));
                setState(prevState => ({ ...prevState, validationFailed: true }));
            }

        }
    }
    const submitMuleOrg = async () => {
        let valid = await Validation(orgList, 'muleOrgValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "organization": state.muleOrganization
            }
            postResource(postObj, 'mule/org', 'muleOrg');
        }
    }
    const submitSonarqube = async () => {
        let valid = await Validation(sonarList, 'sonarValidation')
        if (valid) {
            handleClose();
            var postObj =
            {
                "sonarServer": state.sonarqubeServer,
                "sonarUsername":state.sonarUsername,
                "sonarPassword":state.sonarPassword
                // "sonarConnectionName":state.sonarConncetionName
            }
            postResource(postObj, 'sonarqube', 'sonarqube');
        }
    }
    const postServices = async (url, obj) => {
        console.log("inside post", url, obj)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(obj)
        };
        //const response = await fetch(url, requestOptions);
        // const data = await response.json();
        // return data;
    }
    const getServices = async (url, obj) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                return data;
            });
    }
    const setContent = async (e, item) => {
        e.preventDefault()
        console.log(item)
        setState(prevState => ({ ...prevState, activePage: item }))
        getResource('repo', 'github');
        getResource('elk', 'elk');
        getResource('gitlab', 'gitlab');
        getResource('jenkins', 'jenkins');
        getResource('vm/ec2', 'ec2List');
        getResource('flex', 'flexGateways');
        getResource('gkeresource', 'gkeresource');
        getResource('sonarqube', 'sonarqube');
        getResource('register/flex', 'registeredGateways');
        //getResource('azaccount','azaccount')
        await getResource('mule', 'mule');
        await getResource('mule/org', 'muleOrg');
        // await createVpc();
        await createMuleEnvironment();
    }


    const createMuleSettings = (org) => {
        changeOrg(org)
        setState(prevState => ({ ...prevState, activePage: 'Mule' }));
        handleOpen();
    }
    const createGkeCluster = (account) => {
        changeServiceAccount(account)
        setState(prevState => ({ ...prevState, activePage: 'GkeCluster' }));
        handleOpen();
    }
    const changeOrg = (org) => {
        setState(prevState => ({ ...prevState, newOrg: org }))
    }
    const changeServiceAccount = (account) => {
        setState(prevState => ({ ...prevState, newGkeAcc: account }))
    }
    const createVpc = async () => {
        var vpcObj = {
            "username": state.selectedMuleUsername,
            "password": state.selectedMulePassword,
            "organizationID": state.selectedMuleOrg,
            "vpcName": state.vpcName,
            "region": state.muleRegionName,
            "cidrBlock": state.cidrBlockName
        }
        console.log(vpcObj);
        let vpcCreateObj = await postServices(anypoint + '/vpc/create', vpcObj)
        console.log(vpcCreateObj);
        // this.getAllVpc();
        // this.closeMuleSettings();
    }

    const createRTF = async () => {
        //add two to the rtfrequest list for validation
        let requestList = rtfRequestList.slice();
        let obj1 ={
            label: 'Target Cluster',
            stateVal:'targetCluster'
        }
        let obj2 ={
            stateVal:'deployTargetEnv'
        }
        requestList.push(obj1);
        requestList.push(obj2);
        let valid = await Validation(requestList, 'rtfValidation')
        if(valid){
            closeMuleSettings();
            var rtfObj = {
            "muleSettingsId": state.muleSettingsId,
            "organizationID": state.selectedMuleOrg,
            "mulePassword": state.selectedMulePassword,
            "rtfRegion": state.rtfRegion,
            "rtfVendor": state.rtfVendor,
            "rtfLicenseKey": state.rtfLicenseKey,
            "rtfName": state.rtfName,
            "rtfOrg": state.selectedMuleOrgId,
            "muleUsername": state.selectedMuleUsername,
            "deployTargetEnv": state.deployTargetEnv,
            "targetCluster": state.targetCluster
        }
        setState(prevState => ({ ...prevState, rtfResourceCreating: true }))

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
            body: JSON.stringify(rtfObj)
        };
        console.log(rtfObj);
        fetch(domain + '/api/v1/mule/rtf', requestOptions)
            .then(response => response.json())
            .then(data => {
                setState(prevState => ({ ...prevState, rtfResourceCreating: false }))

                console.log(data);
                closeMuleSettings();
                getResource('mule/rtf', 'rtfData');
                return data;
            });
        }

    }

    const createAWS = async () => {
        let valid = await Validation(awsList, 'awsValidation')
        if (valid) {
            handleClose();
            var postObj = {
                "awsaccesskey": state.awsaccesskey,
                "awsaccesskeyid": state.awsaccesskeyid,
                "location": state.awsregion,
                "awscluster": state.awscluster
            }
            console.log(postObj);
            console.log("Inside aws cluster creation")
            setState(prevState => ({ ...prevState, azureResourceCreating: true }))
            postResource(postObj, 'awsresource', 'awsresources')

        }
    }
    const addGkeAccount = async () => {
        // let valid = await Validation(gkeList, 'gkeValidation')
        // if (valid) {
            handleClose();
            setState(prevState => ({ ...prevState, gkeResourceCreating: true }))
            setTimeout(function () {
                setState(prevState => ({ ...prevState, gkeResourceCreating: false }))
            }, 600000)    
            
            var postObj = {
                "serviceAccEmail": file.replace(/(\r\n|\n|\r)/gm, "").split('\"client_email\": \"')[1].split('",')[0],
                // /"gkeClusterName":state.gkeClusterName,
                // "gkeRegion":state.gkeRegion,
                "serviceAccKeyFile": file                             
            }
            console.log("postobj",postObj)         
            const requestOptions = {
                method: 'POST',
                headers: { 'content-type': 'application/json','x-access-token': localStorage.getItem('token') },
                body: JSON.stringify(postObj)               
              };
            setState(prevState => ({ ...prevState, gkeResourceCreating: true }))
            fetch(domain + '/api/v1/gkeresource', requestOptions)
            .then(response => response.json())
            .then(data => {
                setState(prevState => ({ ...prevState, gkeResourceCreating: false }))
                getResource('gkeresource', 'gkeresource');
            });
       
    }
    const addGkeCluster = async () => {
        // let valid = await Validation(gkeList, 'gkeValidation')
        // if (valid) {
            handleClose();
            setState(prevState => ({ ...prevState, gkeResourceCreating: true }))
            setTimeout(function () {
                setState(prevState => ({ ...prevState, gkeResourceCreating: false }))
            }, 600000)    
            
            var postObj = {
                "serviceAccEmail": state.newGkeAcc,
                "gkeCluster":state.gkeClusterName,
                "gkeRegion":state.gkeRegion
            }
            console.log("postobj",postObj)         
            const requestOptions = {
                method: 'POST',
                headers: { 'content-type': 'application/json','x-access-token': localStorage.getItem('token') },
                body: JSON.stringify(postObj)               
              };
            setState(prevState => ({ ...prevState, gkeResourceCreating: true }))
            fetch(domain + '/api/v1/gkecluster', requestOptions)
            .then(response => response.json())
            .then(data => {
                setState(prevState => ({ ...prevState, gkeResourceCreating: false }))
                getResource('gkecluster', 'gkecluster');
                console.log("cluster",state.gkecluster)
            });
       
    }
    //getVpc = async (orgId, username, password) => {
    //     if (orgId) {
    //         if (this.state.mule.length) {
    //             var gVpcObj = {
    //                 "username": username,
    //                 "password": password,
    //                 "organizationID": orgId
    //             }
    //             console.log(gVpcObj);
    //             let vpcRes = await this.postServices(anypoint + '/vpc/list', gVpcObj);
    //             console.log(vpcRes)
    //             return (vpcRes && vpcRes.Response && vpcRes.Response.data) ? vpcRes.Response.data : null;

    //         }
    //     }

    // }

    // getAllVpc = async () => {
    //     if (this.state.muleOrg.length) {
    //         let vpcLists = [];
    //         await this.state.muleOrg.forEach(async org => {
    //             console.log(org);
    //             await this.state.mule.forEach(async mule => {
    //                 console.log(mule)
    //                 if (mule.muleOrg === org.organization) {
    //                     let vpcres = await this.getVpc(org.organization, mule.muleUsername, mule.mulePassword)
    //                     console.log(vpcres);
    //                     if (vpcres && vpcres.length) {
    //                         vpcres.forEach(async vpcContent => {
    //                             console.log(vpcres)
    //                             vpcContent.organization = org.organization;
    //                             vpcLists.push(vpcContent);
    //                             this.closeMuleSettings();
    //                         })

    //                     }

    //                 }
    //             })

    //         })
    //         this.setState({ vpcLists: vpcLists })
    //         console.log("All VPC LISTS");
    //         console.log(vpcLists);
    //         console.log(this.state.vpcLists)
    //     }
    // }
    // getAllVpn = async () => {
    //     if (this.state.muleOrg.length) {
    //         let vpnLists = [];
    //         await this.state.muleOrg.forEach(async org => {
    //             this.state.mule.forEach(async mule => {
    //                 if (mule.organization === org) {
    //                     if (this.state.vpcLists && this.state.vpcLists.length) {
    //                         await this.state.vpcLists.forEach(async vpc => {
    //                             if (vpc.organization === mule.organization) {
    //                                 let vpnres = this.getVpn(org, mule.muleUsername, mule.mulePassword, vpc.id);
    //                                 if (vpnres && vpnres.length) {
    //                                     await vpnres.forEach(async vpnContent => {
    //                                         console.log(vpnres)
    //                                         vpnContent.organization = org.organization;
    //                                         vpnLists.push(vpnContent);
    //                                     })
    //                                 }
    //                             }
    //                         })
    //                     }
    //                 }
    //             })

    //         })
    //         this.setState({ vpnLists: vpnLists })
    //         console.log("All VPN LISTS");
    //         console.log(vpnLists)
    //     }
    // }
    // getVpn = async (orgId, username, password, vpcid) => {
    //     var gVpnObj = {
    //         "username": username,
    //         "password": password,
    //         "organizationID": orgId,
    //         "vpcID": vpcid
    //     }

    //     let vpnRes = await this.postServices(anypoint + '/vpn/list', gVpnObj);
    //     console.log(vpnRes)
    //     return (vpnRes && vpnRes.Response && vpnRes.Response.data) ? vpnRes.Response.data : null;

    // }
    const createVpn = async () => {
        var vpnObj = {
            "username": state.selectedMuleUsername,
            "password": state.selectedMulePassword,
            "organizationID": state.selectedMuleOrg,
            "vpcID": state.vpcId,
            "vpnName": state.vpcName,
            "remoteIpAddress": state.remoteIpAddress
        }
        let vpnCreate = await postServices(anypoint + '/vpn/create', vpnObj);
        console.log("After VPN Created");
        // this.getAllVpn();
        // this.closeMuleSettings();

    }
    // getMuleEnvironment = async (username, password, orgId) => {
    //     var gEnvObj = {
    //         "username": username,
    //         "password": password,
    //         "organizationID": orgId,
    //     }
    //     let envRes = await this.postServices(anypoint + '/env/list', gEnvObj);
    //     return (envRes && envRes.Response && envRes.Response.data) ? envRes.Response.data : null;

    // }
    // getAllMuleEnvironment = async () => {
    //     if (this.state.muleOrg.length) {
    //         let envLists = [];
    //         await this.state.muleOrg.forEach(async org => {
    //             console.log(org);
    //             await this.state.mule.forEach(async mule => {
    //                 console.log(mule)
    //                 if (mule.muleOrg === org.organization) {
    //                     let envres = await this.getMuleEnvironment(mule.muleUsername, mule.mulePassword, org.organization)
    //                     console.log(envres);
    //                     if (envres && envres.length) {
    //                         envres.forEach(async envContent => {
    //                             console.log(envContent)
    //                             envContent.organization = org.organization;
    //                             envLists.push(envContent);
    //                         })
    //                     }
    //                     // this.setState({ vpcLists: vpcLists })
    //                     // console.log(this.state.vpcLists)

    //                 }
    //             })

    //         })
    //         this.setState({ muleEnvironmentList: envLists })
    //         console.log("All ENV LISTS");
    //         console.log(envLists);
    //         console.log(this.state.muleEnvironmentList)
    //     }
    // }
    const createMuleEnvironment = async () => {
        var envObj = {
            "username": state.selectedMuleUsername,
            "password": state.selectedMulePassword,
            "organizationID": state.selectedMuleOrg,
            "envName": state.environmentName,
            "isProduction": false
        }
        let envCreate = await postServices(anypoint + '/env/create', envObj);
        console.log("Mule Environement");
        // this.getAllMuleEnvironment();
        // this.closeMuleSettings();
    }
    function toggleMuleOrg(muleOrgItem, index) {
        if (muleOrgItem.toggleElement === undefined) {
            muleOrgItem.toggleElement = true
        } else {
            muleOrgItem.toggleElement = !muleOrgItem.toggleElement
        }
        let muleOrganization = state.muleOrg;
        muleOrganization[index] = muleOrgItem;
        setState(prevState => ({ ...prevState, muleOrg: muleOrganization }));      // setMuleOrg(muleOrganization) 
    }
    // function toggleGkeServiceAcc(gkeServiceAcc, index) {
    //     if (gkeServiceAcc.toggleElement === undefined) {
    //         gkeServiceAcc.toggleElement = true
    //     } else {
    //         gkeServiceAcc.toggleElement = !gkeServiceAcc.toggleElement
    //     }
    //     let gkeServiceAccount = state.gkeresource;
    //     gkeServiceAccount[index] = gkeServiceAcc;
    //     setState(prevState => ({ ...prevState, gkeresource: gkeServiceAccount }));      // setMuleOrg(muleOrganization) 
    // }

    const setActiveMuleContent = (val, muleOrg) => {
        console.log("rtf", muleOrg)
        setState(prevState => ({ ...prevState, selectedMuleOrg: muleOrg.organization }))
        state.mule.forEach(mule => {
            if (mule.muleOrg === muleOrg.organization) {
                setState(prevState => ({ ...prevState, selectedMuleUsername: mule.muleUsername }));
                setState(prevState => ({ ...prevState, selectedMulePassword: mule.mulePassword }));
                setState(prevState => ({ ...prevState, selectedMuleOrgId: mule.muleOrgId }));
                setState(prevState => ({ ...prevState, muleSettingsId: mule._id }))
                // this.getOrganizationEnvironment(mule.muleOrgId,mule.muleUsername,mule.mulePassword);
            }
        })
        setState(prevState => ({ ...prevState, activeMuleContent: val }))
        openMuleSettings();
    }
    return (
        <div className="root" >
            <CustomHeader></CustomHeader>
            <main className="content">
               
               
                <Container >
                <Row>
                     <Col className="access-menu" xs={12}>
                            <ul className="sidemenuList">
                                {sidemenuList.map(item => {
                                    return (
                                        <li className={item === state.activePage ? 'selected' : ' ' + item}>
                                            <a onClick={(e) => setContent(e, item)}>{item}</a>  </li>
                                    )
                                })}
                            </ul>
                    </Col>
                    <Col xs={10}>
                         {
                    state.azureResourceCreating ?
                        <Row className="progress-label-wrap">
                            <label>Resource creating in progress.</label>
                        </Row> : state.awsResourceCreating ?
                        <Row className="progress-label-wrap">
                            <label>Resource creating in progress.</label>
                        </Row>:state.gkeResourceCreating ?
                        <Row className="progress-label-wrap">
                            <label>Resource creating in progress.</label>
                        </Row>:state.rtfResourceCreating ?
                        <Row className="progress-label-wrap">
                            <label>Resource creating in progress.</label>
                        </Row>:state.muleOrgNotDelete ? <Row className="progress-label-wrap">
                            <label>Delete the Mule Settings.</label>
                        </Row> : null
                }
                     <Row className="no-margin no-padding">
                        <Col className="settings-content" xs={12}>
                            <div className={'error-container ' + state.validationFailed} >
                                <label>{state.validationError}</label>
                            </div>
                            {getBodyContent()}

                        </Col>
                    </Row>
                    </Col>
                </Row>  
                    <Modal show={state.showModal} onHide={() => handleClose()} className="resourceModal">
                        <AddNewResource onChange={onChange} submitResource={submitResource}
                            state={state} handleChange={handleChange}/>
                    </Modal>
                    <Modal show={state.showMuleSettings} onHide={() => closeMuleSettings()} className="muleResourceModal">
                        <AddActiveMuleContent onChange={onChange} state={state} createVpc={createVpc} createRTF={createRTF}
                            createVpn={createVpn} createMuleEnvironment={createMuleEnvironment} />
                    </Modal>
                    {/* <ConfirmDialog /> */}
                </Container>
            </main>
        </div >
    );
}
export default Accessmanagement;