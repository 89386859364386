
export const descriptions = {
    'Mews': {
        'description': 'This API facilitates integration with the MEWS Property Management System (PMS), providing the following functionalities:',
        'functions': ['Add Customer Details: Create new customer records in the MEWS system.',
            'Retrieve All Customers: Fetch details for all customers in the system.',
            'Search Customers: Look up active customers based on search criteria.',
            'Update Customer Information: Modify existing customer details.',
            'Merge Customers: Combine data of two customers into one record.',
            'Add and Manage Reservations: Add, update, and manage reservations in MEWS.',
            'Cancel Reservations: Cancel reservations based on specific identifiers.'],
        'additionalDetails': ['Utilizes external libraries for data types (datatypeLib) and common headers (traitsLib) for managing client IDs and correlation IDs.',
            'Implements a standardized error response handler (error-response) to maintain consistency across endpoints.']
    },
    'Salesforce': {
        'description': 'This Salesforce Customers System API provides a means to access to Salesforce data for retrieving and managing various objects like Accounts, Contacts, Opportunities, Leads, Campaigns, and Invoices.Functionalities includes:',
        'functions': ['Queries Customer (Account) information from Salesforce.',
            'Creates Customer (Account) in Salesforce.',
            'Updates Customer (Account) in Salesforce.',
            'Queries Contact information from Salesforce.',
            'Creates Contact in Salesforce.',
            'Updates Contact in Salesforce.',
            'Queries related Contacts of a given Account from Salesforce.',
            'Queries Opportunity, Lead, Campaign, and Invoice information from Salesforce.',
            'Creates Opportunity, Lead, Campaign, and Invoice in Salesforce.',
            'Updates Opportunity, Lead, Campaign, and Invoice in Salesforce.'],
        'additionalDetails': ['External libraries are used for common traits and error responses.',
            'Data type libraries define Salesforce object data structures.',
            'Includes client-id-required and correlationId for tracking and identification.',
            'Common error responses are applied for consistent error management.']
    },
    'Workday': {
        'description': 'This API provides a set of operations to retrieve data from Workday, offering the following functionalities:',
        'functions': ['Retrieve Absence Details: Fetches absence data from the Workday system.',
            'Retrieve Worker Details: Retrieves detailed information about workers in the organization.',
            'Fetch Job Profiles: Provides details about job profiles in the system.',
            'Retrieve Location Information: Fetches details about various locations.',
            'Fetch Compensation Grade by ID: Retrieves compensation grade details based on the provided ID.',
            'Retrieve Company Payment Dates: Gets the payment dates for a specific company.',
            'Retrieve Advanced Lookup Tables: Fetches advanced lookup tables in Workday.'],
        'additionalDetails': ['Uses external libraries for data types and traits (traitsLib) for tracking client IDs and correlation IDs.',
            'Implements a common error response handler (error-response) to ensure consistency in error management across all endpoints.']
    },
    'Anaplan': {
        'description': 'This API provides a set of operations for interacting with Anaplan, supporting the following functionalities:',
        'functions': ['Imports a file from a physical location to Anaplan.',
            'Describes import processes, retrieving details of the import status.',
            'Exports data from Anaplan to a physical location.',
            'Describes export processes, providing information on the export status.',
            'Performs delete actions to remove data in Anaplan.',
            'Imports data from Salesforce (SFDC) into Anaplan.',
            'Exports data to Salesforce from Anaplan.',
            'Uploads data from Salesforce to a file in Anaplan.',
            'Describes a file stored in Anaplan.',
            'Processes tasks within Anaplan (e.g., running import or export processes).',
            'Uploads files to Anaplan from external locations.'],
        'additionalDetails': ['Uses external libraries for data types (datatypeLib) and traits (traitsLib) to ensure tracking of client IDs and correlation IDs.',
            'Includes a common error-response handler for consistency in error management across all endpoints.']
    },
    'Servicenow': {
        'description': 'This API provides basic operations for managing incidents in ServiceNow, with the following functionalities:',
        'functions': ['Creates a new incident.',
            'Retrieves details of a specific incident by its ID.',
            'Updates an existing incident.',
            'Deletes an incident by its ID.',
            'Retrieves incident table keys.'],
        'additionalDetails': ['Uses external libraries for data types (datatypeLib) and traits (traitsLib) for tracking client ID and correlation ID.',
            'Implements a common error response handler for consistency in error management.']
    },
    'Generic Datbase': {
        'description': 'This API provides basic CRUD operations for managing employee records in a database system, with the following functionalities:',
        'functions': ['Adds a new employee record.',
            'Retrieves details of all employees.',
            'Retrieves details of a specific employee by ID.',
            `Updates an employee's information.`,
            'Deletes an employee record by ID.'],
        'additionalDetails': ['Uses external libraries for data types (datatypeLib) and traits (traitsLib), including client ID and correlation ID for tracking.',
            'Employs a common error response handler for consistent error management.']
    },
    'Transactis': {
        'description': `The worldstrides-transactis-sapi API provides endpoints to interact with Transactis' payment and customer management systems. It supports operations like health checks, authentication, payment management, and customer data retrieval.`,
        'functions': ['Health Check: Validate API connectivity.',
            'Authentication: Obtain access tokens to interact with the API.',
            'Payment Methods: Manage payment methods for customers.',
            'Customer Management: Search for enrolled customers in the system.',
            'Payments: Submit and retrieve payment requests and statuses.',
            'Refunds: Request refunds on completed transactions.'],
        'additionalDetails': ['Implements error-response traits and headers from common libraries to standardize request headers (client ID, correlation ID) and manage errors consistently.']
    },
    'ContentHub': {
        'description': 'The api-content-hub-sapi API provides endpoints to interact with the Content Hub system. It supports features like system health checks, product and program management, and flyer-related operations.',
        'functions': ['Health Check: Verify API connectivity.',
            'Product Management: Manage and retrieve products.',
            'Program Management: Handle program details and related flyers.',
            'Flyer Requests: Generate different types of program-related flyers.',
            'System Health: Submit system health check requests.'],
        'additionalDetails': ['Implements error-response traits and headers from common libraries to standardize request headers (client ID, correlation ID) and manage errors consistently.']
    },
    'Coupa': {
        'description': 'The api-coupa-sapi performs operations connected to the coupa end system. Below are the operations performed by the SAPI:',
        'functions': ['health : Retrieves the health response of the system.',
            'purchaseOrder : Create and Update purchase order in coupa',
            'requisition : Create Requisition in Coupa',
            'approval : Approve Requisition in Coupa'],
        'additionalDetails': ['Implements error-response traits and headers from common libraries to standardize request headers (client ID, correlation ID) and manage errors consistently.']
    },
    'Lambda': {
        'description': 'This API provides the ability to perform CRUD operations by integrating with AWS Lambda. It includes the following functionalities:',
        'functions': ['Creates a new user in the User table by sending relevant details.',
            'Retrieves all user details from the User table.',
            'Retrieves details of a user by passing their ID.',
            'Updates details of a specific user.',
            'Marks a user as inactive by updating their status in the User table.'],
        'additionalDetails': ['Uses external libraries for data types (datatypeLib) and traits (traitsLib) for tracking client ID and correlation ID.',
            'Implements an error-response handler (error-response) for consistent error handling.']
    },
    'Sitecore': {
        'description': 'This API facilitates integration with the Sitecore content management system to manage assets. The following functionalities are provided:',
        'functions': ['Enables filtering assets based on specific conditions.',
            'Retrieves assets from Sitecore using query logic expressions.',
            'Fetches an asset by its unique identifier.',
            'Allows updating an asset in Sitecore.'],
        'additionalDetails': ['Uses external libraries for data types (sitecoredatatypeLib) and traits (traitsLib) to ensure consistent handling of client ID and correlation ID.',
            'Implements an error-response handler for uniform error management.']
    },
    'Salesforce composite': {
        'description': 'This API facilitates Basic CRUD operations within Salesforce, including managing Salesforce sObjects through composite operations. Below is a detailed overview of the available functionalities:',
        'functions': ['Create sObject Tree (POST /sobjects):Creates one or more sObjects in Salesforce.Requires specifying the object type in the headers.',
            'Create, Update, Upsert, and Delete sObject Collections (/sobjects/collections):Provides batch operations to manipulate multiple Salesforce objects, including accounts and contacts.',
            'Retrieve sObject Collection (POST /sobjects/collections/objects):Retrieves a collection of Salesforce objects based on the provided parameters.'
        ],
        'additionalDetails': ['Error handling is standardized using the error-response trait.',
            'Examples for each request and response type are included to guide API users in correct formatting and structure.']
    },
    'Conga': {
        'description': 'The api-conga-sapi API provides endpoints for interacting with Conga’s system functionalities. This API enables the following operations:',
        'functions': ['Health Check: Verifies connectivity with the API.',
            'Token Retrieval: Fetches an authentication token.',
            'Document Generation: Triggers document creation using Conga templates.',
            'Document Status: Checks the status of the document generation process.'],
        'additionalDetails': ['The API uses external libraries for data types and traits to handle headers like client ID and correlation ID.',
            'Implements a common error response handler for managing errors consistently across all API endpoints.']
    },
    'Magento': {
        'description': 'The apix-magento-sapi API enables integration with the Magento e-commerce system. It provides capabilities to manage authentication, products, customer details, and customer cart functionalities within the Magento platform, facilitating seamless communication with Magento for managing sales operations.',
        'functions': ['Admin Authentication: Handle admin authentication within the system.',
            `Product Management: Retrieve product details from Magento's catalog.`,
            'Customer Management: Fetch customer information and manage customer authentication.',
            'Cart Management: Enable customers to create and manage their shopping carts, estimate shipping costs, and proceed to checkout and order placement.'],
        'additionalDetails': ['Implements error-response traits and common headers (client ID, correlation ID) to standardize request structures and manage errors consistently.']
    },
    'Mongodb': {
        'description': 'The apix-mongodb-sapi API provides seamless integration with MongoDB, enabling users to manage collections and documents efficiently. This API facilitates operations such as creating collections, managing documents, and performing CRUD (Create, Read, Update, Delete) operations, ensuring smooth interaction with MongoDB databases.',
        'functions': ['Create new collections in MongoDB.',
            'Retrieve a list of documents from specified collections.',
            'Create new documents within collections.',
            'Retrieve specific documents by their ID.',
            'Update existing documents by their ID.',
            'Delete documents from the database by their ID.'],
        'additionalDetails': ['External libraries are employed for common traits and error responses.',
            'Data type libraries define the structure of MongoDB documents.',
            'Implements client-id-required and correlationId headers for tracking and identification.',
            'Common error responses are utilized for consistent error management.']
    },
    'Nexio': {
        'description': 'The api-nexio-sapi API allows integration with the Nexio system, providing functionalities like saving and updating card or eCheck tokens, processing transactions, and fetching one-time tokens. It enables seamless payment processing and token management.',
        'functions': ['Health Check: Verify API connectivity.',
            'Token Management: Save, update, and delete card/eCheck tokens.',
            'Transaction Processing: Process card and eCheck payments.',
            'One-Time Token Fetching: Retrieve one-time tokens for transactions.'],
        'additionalDetails': ['Implements error-response traits and headers from common libraries to standardize request headers (client ID, correlation ID) and manage errors consistently.']
    },
    'Odoo': {
        'description': 'This API enables operations for interacting with sales orders from the Odoo database. It provides functionalities to:',
        'functions': ['Retrieve all customer and sales order details.',
            'Create new customers, sales orders, and related data.',
            'Fetch specific records by ID for both customers and sales orders.',
            'Retrieve product templates and order lines.'],
        'additionalDetails': ['Utilizes external libraries for data types (datatypeLib) and traits (traitsLib) to manage client ID and correlation ID tracking.',
            'Implements a common error response handler for consistent error handling across the API.']
    },
    'Netsuite': {
        'description': 'The api-netsuite-sapi is designed to interact with the Netsuite system, offering functionalities such as health checks, managing vendors, customers, sales orders, contacts, and payment records.',
        'functions': ['Health Check: Verifies API connectivity.',
            'Vendor Management: Retrieve and update vendor records.',
            'Customer Management: Create or update customer records.',
            'Sales Orders: Create or update sales orders.',
            'Contact Retrieval: Fetch contact details based on vendor internal ID.',
            'Payment Records: Create payment records in Netsuite.'],
        'additionalDetails': ['Implements error-response traits and headers from common libraries to standardize request headers (client ID, correlation ID) and manage errors.']
    },
    'Amadeus': {
        'description': 'The api-amadeus-sapi API provides connectivity with the Amadeus system for retrieving Passenger Name Record (PNR) data and validating API health. It offers a simple yet efficient way for managing airline reservations and integrations with the Amadeus platform.',
        'functions': ['Health Check: Verifies API connectivity.',
            'PNR Retrieval: Fetches PNR information from the Amadeus system.'],
        'additionalDetails': ['Utilizes error-response traits for handling errors consistently.',
            'Includes common headers like client-id and correlationId for tracking requests.']
    },
    'General Process & Experience API': {
        'description': 'This is a generic experience API designed to handle CRUD operations by interacting with a generic process API (PAPI). The API provides endpoints to create, retrieve, update, and delete resources. The actual resource name and use case will be replaced in real-time implementation.',
        'functions': ['GET /resourceName: Retrieve all resources.',
            'POST /resourceName: Create a new resource.',
            'GET /resourceName/{id}: Retrieve a specific resource by its ID.',
            'PUT /resourceName/{id}: Update a resource by its ID.',
            'DELETE /resourceName/{id}: Delete a resource by its ID.'],
        'additionalDetails': ['Traits: Implements error handling, client ID enforcement, and correlation ID.',
            'Data Types: Utilizes genericRequest and genericResponse data types for requests and responses.',
            'Media Type: JSON (application/json).',
            'Protocols: HTTP and HTTPS supported.']
    },
    'Integration AI Agent': {
        'description': 'The AI template automates the development flow for MuleSoft projects by taking user input (via a chatbot-like interface) and dynamically generating required configuration files, such as implementation.xml, pom.xml, and properties files. It also integrates with CI/CD pipelines for seamless deployment and testing, reducing manual effort and human error in the development lifecycle.',
        'functions': ['User Input Processing: Accepts user commands or queries in natural language and translates them into appropriate MuleSoft configurations.',
'Dynamic Code Generation: Generates the necessary Mule configuration files based on the user’s input, covering XML for flows, Maven dependencies, and environment-specific properties.'],
        'additionalDetails': ['CI/CD Integration: Automates the generation of CI/CD pipelines, enabling continuous integration and deployment with tools like Jenkins, GitLab, or Azure DevOps.',
'Error Handling and Validation: Includes validation mechanisms to ensure that generated code is syntactically correct and that dependencies are resolved.']
    },
    'Migration AI Agent': {
        'description': 'Migration Agent is designed to automate the conversion of .bpel (Business Process Execution Language) code into MuleSoft integration flows. This tool helps organizations migrate their existing BPEL-based processes into MuleSoft, enabling them to leverage MuleSoft’s capabilities for modern, scalable, and cloud-native integrations.',
        
    },
    'Design AI Agent': {
        'description': 'Design Agent is designed to generate and view the plantuml according to the user’This design template automates the generation of project requirement descriptions into a PlantUML sequence diagram for MuleSoft integrations. By capturing essential integration details such as API calls, transformations, and external system interactions, the template streamlines documentation and visualization for developers and architects’s requirement',
        
    }

}