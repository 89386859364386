import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";// Import theme here

const CodeHighLighter = ({ code }) => {
  return(
<SyntaxHighlighter language="xml" style={prism} customStyle={{
                    width: '100%',              // Ensure SyntaxHighlighter takes full width of the parent
                    whiteSpace: 'pre-wrap',      // Wraps long lines
                    wordBreak: 'break-word',     // Breaks words if they're too long
                    overflowX: 'auto',         // Prevent horizontal scrolling
                    overflowY: 'scroll',       // Allow vertical scrolling
                    display: 'block',       // Allow vertical scrolling
                  }}> 
                   {code}
</SyntaxHighlighter>
);
};

export default CodeHighLighter;
