import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import CustomHeader from "../../components/header";
import './dasboard.scss';
import { Container, Col, Row, Card, ListGroup, ListGroupItem, Figure, Image, Button, Table, Modal, CardGroup } from 'react-bootstrap';
import * as moment from "moment"
import notFoundIcon from '../../assets/icons/nodata.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from 'react-router-dom';
import CardContainer from "../../components/cardContainer";
import url from "../../config.js"
import { archetypesStack, techStack, cloudStack } from "../../constants/templateConstants";
import { pjtTableHeader } from "../../constants/tableHeaderConstants";
import { TableHeader, NoSettingsFound } from "../../components/tableComponent/index"
import { ValidationComponent } from "../../components/new-resource-form/index.js";
import MultiCard from '../../components/multiCard';
import styled from 'styled-components';
import ImageCard from "../../components/imageCard";


const Title = styled.p`
  color: #fff;
  font-weight: 100;
  @media (max-width: 100px) {
    font-size: 0.2rem;
  }
`
const Date = styled.div`
  color: #ccc;
  font-weight: 300;
  margin: 5px 0;
  
  @media (max-width: 10px) {
    font-size: 0.8rem;
  }
`
const Description = styled.p`
  color: #fff;
  font-weight: 300;
  padding:10px;
  @media (max-width: 10px) {
    font-size: 0.75rem;
  }
`


const domain = url.domain;
// const archetypesStack=myConst.archetypesStack
// const cloudStack= myConst.cloudStack
// const techStack = myConst.techStack

function Dashboard() {
  const navigate = useNavigate()

  // static contextTypes = {
  //   router: PropTypes.object
  // }

  const [state, setState] = useState({
    resources: [],
    resource_group_name: [],
    azure_container: [],
    azure_cluster: [],
    projects: [],
    showModal: false,
    projectStep: 1,
    projectName: '',
    validationClass: '',
    projectArchetype: '',
    activeTeckStackIndex: '',
    activeCloudIndex: '',
    activeArcheIndex: ''
  }
  )
  const showContainer = () => {
    if (state.myclass === '') {
      setState(prevState => ({ ...prevState, myclass: 'slide' }))
    }
    else {
      setState(prevState => ({ ...prevState, myclass: '' }))
    }
  }
  function getProject() {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/project', requestOptions)
      .then(response => response.json())
      .then(data => {

        if (data) {
          setState(prevState => ({ ...prevState, projects: data.projects }))
        }
      });
  }
  const gotproject = (id, name,archeType) => {
    if (archeType === 'ai-template'){
      navigate(`/integrationAgent/${name}/${id}`);

    }else if (archeType === 'design-template'){
      navigate(`/designAgent/${name}/${id}`);

    }else if (archeType === 'migration-template'){
      navigate(`/migrationAgent/${name}/${id}`);

    }
    else{
      navigate(`/projectDetails/${name}/${id}`);
    }
    
  }

  //  const gotproject = (id, name) => {
  //   navigate(`/deployment/${name}/${id}`);
  // }

  function onChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }
  function getResource() {
    const requestOptions = {
      method: 'GET',
      headers: { 'x-access-token': localStorage.getItem('token') }
    };
    fetch(domain + '/api/v1/azresource', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setState(prevState => ({ ...prevState, resources: data }))
        if (data.length) {
          data.map((res) => {
            console.log(res);
            var resources = state.resource_group_name.concat(res.resource_group_name);
            setState(prevState => ({ ...prevState, resource_group_name: resources }))
            console.log(state.resource_group_name);
          });
        }

      });
  }

  useEffect(() => {
    getResource();
    getProject()
  }, [])
  const setSelectProperties = (rg) => {
    state.resources.map(res => {
      console.log(res);
      console.log(rg);
      if (res.resource_group_name === rg) {
        console.log("inside");
        let container = state.azure_container.concat(res.azure_container_registry_name);
        let cluster = state.azure_cluster.concat(res.aks_cluster_name);
        setState(prevState => ({ ...prevState, azure_container: container }))
        setState(prevState => ({ ...prevState, azure_cluster: cluster }))
        console.log(state.azure_container);
      }
    })
  }

  const submit = () => {
    if (!state.projectName || state.projectName === '') {
      setState(prevState => ({ ...prevState, validationClass: 'validation-error' }))

    }
    else {
      console.log("STATE", state);
      setState(prevState => ({ ...prevState, projectStep: 1 }))
      handleClose();
      var postObj =
      {
        "cloudType": state.cloudType,
        "createdOn": moment().format("DD-MM-YYYY hh:mm:ss"),
        "projectName": state.projectName,
        "projectType": state.projectType,
        "projectArchetype": (!state.projectArchetype || state.projectArchetype === '') ? state.projectType + '-template' : state.projectArchetype,
        "processType": (state.projectArchetype === "apix-mule-multi-tier" || state.projectArchetype === "apix-education-multi-tier" || state.projectArchetype === "apix-banking-multi-tier" || state.projectArchetype === "open-finance-multi-tier"|| state.projectArchetype === "api-general-apis") ? "multiple" : "single"
      }

      console.log("post project", postObj)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
        body: JSON.stringify(postObj)
      };
      fetch(domain + '/api/v1/project', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          getProject();
        });
    }
  }
  const handleOpen = () => {
    console.log('Show modal')
    setState(prevState => ({ ...prevState, showModal: true }))
    // setState(prevState => ({ ...prevState, projectArchetype: archetypesStack[0].value }))
  }
  const handleClose = () => {
    setState(prevState => ({ ...prevState, showModal: false }))
  }
  const next = () => {
    setState(prevState => ({ ...prevState, validationClass: '' }))
    let projectStep = state.projectStep;
    console.log("projectStep", projectStep)
    setState(prevState => ({ ...prevState, validationClass: 'valid' }))
    console.log(state);
    console.log(state.projectType)
    switch (projectStep) {
      case 1:
        if (!state.projectType || state.projectType === '') {
          setState(prevState => ({ ...prevState, validationClass: 'validation-error' }))
          return
        }

        break;
      case 2:
        if (!state.cloudType || state.cloudType === '') {
          setState(prevState => ({ ...prevState, validationClass: 'validation-error' }))
          return
        }
        break;
      case 3:
        if (!state.projectName || state.projectName === '') {
          setState(prevState => ({ ...prevState, validationClass: 'validation-error' }))
          return
        }
        break;
      case 4:
        if (!state.projectArchetype || state.projectArchetype === '') {
          setState(prevState => ({ ...prevState, validationClass: 'validation-error' }))
          return
        }
        break;
      default:
        break;
    }
    setState(prevState => ({ ...prevState, projectStep: projectStep + 1 }))

  }
  const prev = () => {
    let projectStepTemp = state.projectStep;
    setState(prevState => ({ ...prevState, projectStep: projectStepTemp - 1 }))
  }
  const handleSelect = (type) => event => {
    console.log(type)
    console.log(event);
    if (type === 'cloud')
      setState(prevState => ({ ...prevState, cloudType: event.value }))
  }
  const projectStepLength = () => {
    return (state.projectType === 'mule' ? 4 : 3);
  }
  const setProjectArchetype = (val) => {
    setState(prevState => ({ ...prevState, projectArchetype: val }));
  }
  const setSelectedArchetype = (index, val) => {
    console.log("setSelectedArchetype", val)
    setState(prevState => ({ ...prevState, projectArchetype: archetypesStack[archetypesStack.map((item, i) => item.name === val ? i : '').filter(String)].value }))
    setState(prevState => ({ ...prevState, activeArcheIndex: index }))
  }

  const getModalBody = () => {
    console.log("getModalBody", state)
    let cloudType = state.cloudType;
    let projectType = state.projectType;
    switch (state.projectStep) {
      case 1:
        const sampleFuncion = () => {
          console.log("called");
        }
        const setProjectType = (index, item) => {
          setState(prevState => ({ ...prevState, projectType: item }))
          setState(prevState => ({ ...prevState, activeTeckStackIndex: index }))
          console.log(state.activeTeckStackIndex);

        }
        return (
          <div>
            <div>
              <div className="text-label-wrap">
                <label className="form-text-label">Project Type</label>
                {state.validationClass !== '' && state.validationClass !== 'valid' ? <ValidationComponent message='please select a project type' /> : null}

              </div>
              <div className="text-input-wrap">
                <Row>
                  {techStack.length && techStack.map((techItem, index) => {
                    return (<ImageCard className={index === state.activeTeckStackIndex ? 'active' : ''} data={techItem} onClick={(val) => setProjectType(index, val)} gridVal="3" index={index} />)
                  })}
                </Row>
                {/* <ImageCard data={techStack} onClick={(val) => setProjectType(val)} gridVal="6"/> */}
                {/* <CardContainer data={techStack} onClick={(val) => setProjectType(val)}></CardContainer>   */}

              </div>
            </div>
          </div>
        )
      case 2:
        const setCloudType = (index, item) => {
          setState(prevState => ({ ...prevState, cloudType: item }))
          setState(prevState => ({ ...prevState, activeCloudIndex: index }))

        }
        return (<div>
          <div>
            <div className="text-label-wrap">
              <label className="form-text-label">{state.projectType === 'mule' ? 'CI/CD Server' : 'Cloud Type'}</label>
              {state.validationClass !== 'valid' ? <ValidationComponent message='please select a value here' /> : null}

            </div>
            <div className="text-input-wrap">
              <Row>
                {cloudStack.length && cloudStack.map((cloudItem, index) => {
                  return (<ImageCard className={index === state.activeCloudIndex ? 'active' : ''} data={cloudItem} onClick={(val) => setCloudType(index, val)} gridVal="3" index={index} />)
                })}
              </Row>
              {/* <CardContainer data={cloudStack} onClick={(val) => setCloudType(val)}></CardContainer> */}

            </div>
          </div>
        </div>

        )
      case 3:
        return (<div>
          <div className="text-label-wrap">
            <label className="form-text-label">Project name</label>
            {state.validationClass !== 'valid' ? <ValidationComponent message='please provide a value here' /> : null}

          </div>
          <div className="text-input-wrap">
            <input className={"form-imput project-name " + state.validationClass} placeholder="Project Name" name='projectName' onChange={onChange}></input>
          </div>
        </div>)
      case 4:
        return (
          <div>
            <div  >
              <div className="text-label-wrap">
                <label className="form-text-label">Select Mule Project Template</label>
              </div>
              <div className="template-add-wrap"  style={{ 'height': '500px', 'overflow-y': 'scroll' }}>
              {/* <div className="template-input-wrap"  > */}

                {/* <CardContainer data={archetypesStack} onClick={(val) => setSelectedArchetype(val)}></CardContainer> */}
                <Row>
                  {archetypesStack.length && archetypesStack.map((archeItem, index) => {
                    return (<ImageCard className={index === state.activeArcheIndex ? 'active' : ''} data={archeItem} onClick={(val) => setSelectedArchetype(index, val)} gridVal="3" index={index} />)
                  })}
                </Row>
                {/* </div> */}

              </div>
            </div>
          </div>
        )
      default: break;
    }
  }
  console.log("state", state)
  return (
    <div className="root" >
      <CustomHeader></CustomHeader>
      <main className="content">

        <div className="project-overview-wrap">
          <Container className="project-overview-container" className={!state.showModal ? 'show' : 'hide'}>

            <Row className="project-container-row">
              <Col xs={12} className=" projects-container">
                <Row className=" projects-header-wrap">
                  <Col xs={1}>
                    <div className="sub-header">Projects</div>
                  </Col>
                  <Col xs={11}>
                    <Button className='create-project-btn' variant="contained" onClick={() => handleOpen()}> Create New</Button>
                  </Col>

                </Row>
                <Row xs={2} md={4} className="card-row" >
                  {state.projects && state.projects.length >= 1 && state.projects.map((project) => {
                    const headerData = {};
                    headerData[project.projectName] = project.projectType;
                    const footerData = {}
                    footerData[project.cloudType] = moment(project.createdAt).format('LLL');
                    return (<MultiCard headerData={headerData} footerData={footerData} theme="light" onClick={() => gotproject(project._id, project.projectName,project.projectArchetype)} />)
                  }
                  )}
                </Row>
                {/* {
                    (state.projects && state.projects.length) ?
                      <Row className="table-wrap">
                        <table className="custom-table" hover>
                          <TableHeader list={pjtTableHeader}/>                         
                          <tbody >
                            {state.projects && state.projects.length && state.projects.map(project => {
                              return (
                                <tr onClick={() => gotproject(project._id)}>
                                  <td>{project.projectName}</td>
                                  <td>{project.projectType}</td>
                                  <td>{project.createdAt}</td>
                                  <td></td>
                                </tr>


                              )

                            })
                            }
                          </tbody>

                        </table >
                      </Row> :<NoSettingsFound message='No Projects found'/>                      
                  } */}
              </Col>
            </Row>
          </Container>
          <Container className="create-project-wrap " className={state.showModal ? 'show' : 'hide'}>

            <div>
              {getModalBody()}
            </div>
            <div className="project-step-btn-group">
              {state.projectStep === 1 ? <Button variant="secondary" onClick={() => handleClose()}>
                Cancel
              </Button> : <Button variant="secondary" onClick={() => prev()}>
                Prev
              </Button>}

              {state.projectStep < projectStepLength() ? <Button variant="primary" onClick={() => next()}>
                Next
              </Button> : <Button variant="primary" onClick={() => submit()}>
                Finish
              </Button>}

            </div>
          </Container>

        </div>
      </main>
    </div>
  );
}

export default Dashboard;