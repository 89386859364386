import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { NoSettingsFound } from '../../utilities';
import './customTable.scss';

export function CustomTable({ headers, data, onClick }) {
  const [chosen, setChosen] = useState();
  console.log("data in Custome table", data);
  const clickFunction = (item) => {
    console.log(item)
    setChosen(item);
    // clickhandler(item.name)
    onClick(item.name)
  }

  return (
    <>
      {
        data && data.length ?
          <Table className="customTable table" variant='light'>
            <thead className="headBg">
              <tr>
                <th>
                  <Form.Check
                    type="checkbox"
                    id="select-all-td"
                  />
                </th>
                {headers.map(header => {
                  return (
                    <th scope="col" key={header}>
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((datum, index) => {
                return (
                  <tr key={datum._id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${datum._id}-${index}`}
                      />
                    </td>
                    {headers.map((header, index) => {
                      return (
                        <td key={index}>
                          <span>{datum[header]}</span>
                        </td>
                      )
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table> : <NoSettingsFound message="No assets found, Please create your first asset by clicking the add button" />
      }


    </>
  )
}
// export default CustomTable

export function CustomeTableWithNavigation({ headers, data, onClick }) {
  console.log("data in Custome table", data);
   return (
    <>
     {data && data.length ?    
          <Table className="customTable table" variant='light'>
            <thead className="headBg">
              <tr>
                <th>
                 
                </th>
                {headers.map(header => {
                  return (
                    <th scope="col" key={header}>
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
            {data.map((item, index) => {
                return (
                  <tr onClick={() => onClick(item['name'])}>
                    <td>
                      <span>{index + 1}</span>
                    </td>
                    <td key={item['name']}>
                      <span>{item['name']}</span>
                    </td>
                    <td key={item['name']}>
                      <span>{item['status']}</span>
                    </td>

                  </tr>
                );
              })}


            </tbody>
          </Table> :null
    }


    </>
  )
}