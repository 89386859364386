import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import CustomHeader from "../../components/header/index.jsx";
import TextField from "@material-ui/core/TextField";
import { CustomeTableWithNavigation } from "../../components/customTable/index";
import CodeHighLighter from "../../components/codeHighLighter/index";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import './migration.scss';
import { Container, Col, Row, Button } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from 'react-router-dom';
import url from "../../config.js"
import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JSZip from "jszip";


const domain = url.domain;
function Migration() {
  let { projId, projName } = useParams();

  const navigate = useNavigate()
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);


  const [state, setState] = useState({
    loading: false,
    fileUploaded: false,
    fileSelected: false,
    fileName: '',
    tableData: {
    },
    extractedFiles: []

  })


  useEffect(() => {
    var socketUrl = url.SOCKET

    console.log("URLLLLLLLL", socketUrl)
    const socket = socketIOClient(socketUrl, { rejectUnauthorized: true });
    console.log("socket ai chat", socket)
    socket.on("connect", function (e) {
      console.log("connect", e);
      console.log("STATE", state.projects);

    });


    // socket.on('migrated data', function (response) {
    //   console.log("migrated data", response)
    //   setState(prevState => ({ ...prevState, sourceFileContent: response.message, [state.fileName + 'DestinationContent']: response.message }));
    //   localStorage.setItem('migrated data', response.message)


    // });
  })

  const getFileNameWithoutExtension = (path) => {
    return path.split('/').pop().split('.')[0];
  };


  const goBack = () => {
    setState(prevState => ({ ...prevState, fileSelected: false }));
  }


  const goBuild = () => {
    localStorage.setItem('message', state.chat)
    navigate(`/projectDetails/${projName}/${projId}`);
  }

  const onClick = (filename) => {
    // const fileName = filename.split(".")[0].split("/")[1] === undefined ? filename.split(".")[0] : filename.split(".")[0].split("/")[1]
    const fileName = filename.split('/').pop().split('.')[0] === undefined ? filename.split(".")[0] : filename.split('/').pop().split('.')[0]


    console.log("file name in onclick", fileName)
    setState(prevState => ({ ...prevState, fileSelected: true,  selectedFileName: fileName }));
  }


  const handleFileChange = async (event) => {
    // const selectedFiles = Array.from(event.target.files);
    // console.log("selectedFiles",selectedFiles)
    // setFiles((prevFiles) => [...prevFiles, ...selectedFiles])
    const selectedFile = event.target.files[0];

    setFile(selectedFile);

    if (selectedFile.name.endsWith(".zip") || selectedFile.name.endsWith(".jar") || selectedFile.name.endsWith(".ear")) {

      let zipFile = selectedFile;

      // Convert JAR to ZIP (just rename it)
      // if (selectedFile.name.endsWith(".jar")) {
      //   zipFile = new File([selectedFile], selectedFile.name.replace(".jar", ".zip"), { type: "application/zip" });
      // }
      if (!zipFile) return;

      const zip = new JSZip();
      const zipData = await zip.loadAsync(zipFile);
      // Convert object to an array of entries
      const entries = Object.entries(zipData.files).filter(([_, entry]) => !entry.dir);;

      // Remove the first entry
      // entries.shift();

      // Convert back to an object
      const updatedFiles = Object.fromEntries(entries);

      console.log("zipDATA", zipData)

      const extractedFiles = [];
      for (const filename of Object.keys(updatedFiles)) {
        const fileContent = await zipData.files[filename].async("text");
        setState(prevState => ({ ...prevState, extractedFiles: [...prevState.extractedFiles, { name: filename, content: fileContent, status: "not migrated" }] }))

        extractedFiles.push({ name: filename, content: fileContent, status: "not migrated" });
      }

      console.log("EXTRACTED FILES", extractedFiles)

    }

    // if (!selectedFile.name.endsWith(".bpel")) {
    //   alert("Please select a .bpel file!");
    //   return;
    // }

    else {
      const reader = new FileReader();
      reader.readAsText(selectedFile); // Read as text

      reader.onload = () => {
        const xmlContent = reader.result;
        setState(prevState => ({
          ...prevState,
          extractedFiles: [...prevState.extractedFiles, {
            name: selectedFile.name,
            content: xmlContent,
            status: "not migrated"
          }]
        }));
      };

    }
  };


  const migrationAPI = (postObj, name) => {
    return new Promise((resolve, reject) => {
      try {
        console.log("post upload", postObj);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token"),
          },
          body: JSON.stringify(postObj),
        };

        console.log("Inside submit", domain, requestOptions);

        fetch(domain + "/api/v1/migration", requestOptions)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.text(); // Read as text first
          })
          .then((text) => {
            const data = text ? JSON.parse(text) : {}; // Parse only if not empty
            console.log("RESPONSE", data);

            setState((prevState) => ({
              ...prevState,
              loading: false,
              fileUploaded:true,
              [data.fileName + 'DestinationContent']: data.message,
              extractedFiles: prevState.extractedFiles.map((item) =>
                item.name === name ? { ...item, status: "migrated" } : item
              ),
            }));

            resolve();
          })
          .catch((error) => {
            console.error("Error in migration API:", error);
            reject(error);
          });
      } catch (error) {
        console.error("Error uploading file:", error);
        reject(error);
      }
    });
  };

  const handleMigrate = async (keyword) => {
    setState(prevState => ({ ...prevState, loading: true }))
    var postObj;
    if (keyword === 'migrate') {
      if (!file && state.sourceFileContent === '') {
        toast.error("Please upload file add source contents in the text area");

        return;
      }

      // const formData = new FormData();
      // formData.append("file", file);
      for (const item of state.extractedFiles) {
        const projectName = item.name.split('/').pop().split('.')[0] === undefined ? item.name.split(".")[0] : item.name.split('/').pop().split('.')[0]
        // const parts = item.split("/"); // Split path by "/"
        // let parentFolder=null;
        // if (parts.length > 1) {
        //   parentFolder = parts[parts.length - 2]; // Get the immediate parent folder
        //   console.log(`File: ${item}, Parent Folder: ${parentFolder}`);
        // } else {
        //   console.log(`File: ${item}, No Parent Folder (Root)`);
        // }
        setState(prevState => ({
          ...prevState, fileName: item.name,
          [projectName + 'SourceContent']: item.content,
          loading: true,
          extractedFiles: prevState.extractedFiles.map((file) =>
            file.name === item.name ? { ...file, status: "in progress" } : file
          ),
        }))
        postObj = {
          fileContent: item.content,
          projName: projectName,
          // parentFolder,
          keyword
        };
        await migrationAPI(postObj, item.name);
      };
    } else {
      postObj =
      {
        chatQuery: state.chat,
        projName: state.selectedFileName,
        keyword,
        fileContent: state[state.selectedFileName + 'DestinationContent']
      }
    }


  };





  function onChange(event) {
    const { value } = event.target;
    setState(prevState => ({ ...prevState, chat: value }));
  }


  const getModalBody = () => {
    console.log("Object.keys(state.extractedFiles).length", Object.keys(state.extractedFiles).length)
    const divHeight = Object.keys(state.extractedFiles).length === 1 ? 120 : Object.keys(state.extractedFiles).length === 2 ? 180 : 220
    return (

      <>
        {
          state.loading ?
            <><Row className="progress-label-wrap">
              <label>Process in progress.</label>
            </Row>
              <br /></> : state.fileUploaded ? <><Row className="progress-label-wrap">
                <label>File migrated Successfully</label>
              </Row>
                <br /></> : null}
        {state.fileSelected ?

          <>
            <Row className="projects-header-wrap">
              <Col xs={6}>
                <label className="label">SourceContent</label>
                <div className="code-wrap" style={{ 'height': '350px' }}>

                  <CodeHighLighter code={state[state.selectedFileName + 'SourceContent']} />
                </div>
              </Col>
              <Col xs={6}>
                <label className="label">MuleContent </label>
                <div className="code-wrap" style={{ 'height': '350px', 'whiteSpace': 'pre-wrap' }}>
                  <CodeHighLighter code={state[state.selectedFileName + 'DestinationContent']} />
                </div>
              </Col>
            </Row>

            <Row className="projects-header-wrap" >
              <Col xs={1}>
                <Button className="button-chat" variant="primary" size="sm" onClick={() => goBack()}>
                  Back
                </Button>
              </Col>

              <Col xs={10}>
                <TextField
                  className="chat-input"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="chat"
                  placeholder="Enter the message"
                  name="chat"
                  onChange={(e) => onChange(e)}
                />
              </Col>
              <Col xs={1}>
                <Button className="button-chat" variant="primary" size="sm" onClick={() => handleMigrate("chat")}>
                  Send
                </Button>
              </Col>
            </Row>

          </>
          : <>
            <Row className="projects-header-wrap">
              <Col xs={3}>
                <div className="text-input-wrap">
                  <label className="label">Model</label>
                  <select className="browser-default custom-select" name='gitType' onChange={onChange}>
                    <option value="model">gpt 4 </option>
                    <option value="github"> gpt 4 </option>
                    <option value="bitbucket"> AWS bedrock </option>
                    <option value="bitbucket"> NJC LLM </option>
                  </select>
                </div>
              </Col>

              <Col xs={3}>
                <div className="text-input-wrap">
                  <label className="label">Source</label>
                  <select className="browser-default custom-select" name='source' onChange={onChange}>
                    <option value="source">Jitterbit </option>
                    <option value="source">Oracle SOA </option>
                    <option value="oracle"> Oracle OSB</option>
                  </select>
                </div>
              </Col>
              <Col xs={3}>
                <label className="label">Destination</label>

                <div className="text-input-wrap">
                  <select className="browser-default custom-select" name='destination' onChange={onChange}>
                    <option value="source">Mule </option>
                    <option value="oracle"> Mule </option>
                  </select>
                </div>
              </Col>
              {/* <Col xs={5}/>
            <Col xs={4}>
                       <label className="label">File content/Please add Source Code here</label>                       
                        <textarea
                         className="ai-code-wrap outline-none"
                         type="text"
                         id="sourceFileContent"
                         value={state.sourceFileContent}
                            onChange={(e) => onFileContentEdit(e)}
                          />
            </Col> */}

            </Row>
            <Row className="projects-header-wrap">


            </Row>
            <Row className="projects-header-wrap">


              <Col xs={3}>
                {/* <input
                  type="file"
                  id="uploadInput"
                  multiple
                  webkitdirectory="" // Folder selection for Chrome & Edge
                  directory=""        // Some browsers recognize this
                  onChange={handleFileChange}
                  className="hidden"
                /> */}



                <input type="file" onChange={handleFileChange} />
              </Col>
              <Col xs={3}>
                <Button variant="primary" size="sm" onClick={() => handleMigrate("migrate")}>Migrate</Button>
              </Col>
              {state.fileUploaded ?
                <Col xs={3}>
                  <Button variant="primary" size="sm" onClick={() => goBuild()}>Build & Deploy</Button>
                </Col> : null}
            </Row>
            {Object.keys(state.extractedFiles).length ?
              <Row className="projects-header-wrap">
                <div className="tab-content-data-wrap" style={{ 'overflow-y': 'scroll', 'height': divHeight }}>
                  <CustomeTableWithNavigation headers={["file", "status"]} data={state.extractedFiles} onClick={(key) => onClick(key)}></CustomeTableWithNavigation>
                </div>
              </Row> : null}</>}
      </>
    )



  }





  console.log("state", state)
  return (
    <div className="root" >
      <CustomHeader></CustomHeader>
      <main className="content">

        <div className="project-overview-wrap">
          <Container className="project-overview-container" >

            <Row className="project-container-row">
              <Col xs={12} className=" projects-container">
                <Row className="projects-header-wrap">
                  <Col xs={8}>
                    <div className="sub-header">Migration Agent</div>
                  </Col>


                </Row>

                {getModalBody()}


              </Col>
            </Row>
          </Container>
        </div>
      </main>
    </div>
  );
}

export default Migration;
